<template>
  <!--  KKP Mobile  -->
  <div>
    <b-overlay :show="isTogging">
      <div class="row">
        <div class="col-sm-4 col-lg-3">
          <h5>
            KKP Mobile App
            <AutoBankingStatus :status="!!kkpAccount" />
            <br>
            <small class="text-muted">(รองรับถอนเท่านั้น)</small>
          </h5>
        </div>

        <div class="col-sm-8 col-lg-9 pt-2">
          <b-form-checkbox
              v-if="kkpAccount"
              id="kkpAccountStatus"
              v-model="kkpAccount.isEnabled"
              :disabled="isTogging"
              name="kkpAccountStatus"
              switch
          >
            เปิดใช้งาน
          </b-form-checkbox>
          <AutoBankingLinkButton
              :status="!!kkpAccount"
              class="mr-1"
e-              @click="$bvModal.show('kkp-mobile-app-modal')"
          />
          <AutoBankingUploadImageButtonButton
            v-if="!!kkpAccount"
            @click="$bvModal.show('kkp-upload-image-modal')"
          />
          <KkpMobileAppModal :agent-bank-account-id="bankAccountForm.id" :kkp-account="kkpAccount" />
          <KkpImageModal :agent-bank-account-id="bankAccountForm.id" :kkp-account="kkpAccount" />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoKkpForm',
  components: {
    KkpMobileAppModal: () => import('./mobile-app-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
    KkpImageModal: () => import('./upload-image-modal'),
    AutoBankingUploadImageButtonButton: () => import('../auto-banking-upload-image-button'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        kkpAccount: null,
      })
    }
  },
  data() {
    return {
      kkpAccount: null,
      isTogging: false,
    }
  },
  watch: {
    'kkpAccount.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('kkp-mobile')
      }
    },
  },
  created() {
    this.kkpAccount = this.bankAccountForm.kkpAccount
  },
  methods: {
    ...mapActions(['toggleBankingStatus']),
    toggleStatus(banking) {
      this.toggleBankingStatus({
        id: this.bankAccountForm.id,
        banking,
      })
    }
  }
})
</script>

<style scoped>

</style>